<template>
	<div>
		<b-table
			ref="tableRef"
			class="position-relative"
			responsive
			:items="table.data.items"
			:fields="[
				...table.fields.value,
				...[
					{
						visible: true,
						label: '',
						key: 'actions',
						filter: false,
						tdClass: 'td-actions',
					},
				],
			]"
			:sort-by.sync="table.order.value"
			:sort-desc.sync="table.desc.value"
			primary-key="id"
			show-empty
			empty-text="Нет записей"
			@row-clicked="onClick"
		>
			<template #head(actions)="data">
				<div style="width: 100px; float: right" class="d-flex justify-content-around">
					<b-button
						variant="primary"
						class="btn-icon p-50"
						style="float: right"
						@click="
							() => {
								isSlectColumnActive = true
							}
						"
					>
						<feather-icon icon="SettingsIcon" size="15" />
					</b-button>
				</div>
			</template>
			<template v-if="hasFilters" slot="top-row" slot-scope="{ fields }">
				<td v-for="field in fields" :key="field.key" style="padding: 2px">
					<b-form-input
						v-if="field.filter == 'string'"
						v-model="filters[field.key]"
						:placeholder="field.label"
						width="100%"
						@keyup.enter="fetchList"
					/>
					<b-form-input
						v-else-if="field.filter == 'number'"
						v-model="filters[field.key]"
						:placeholder="field.label"
						:formatter="format.number"
						@keyup.enter="fetchList"
					/>
					<b-form-select
						v-else-if="field.filter == 'option'"
						v-model="filters[field.key]"
						:placeholder="field.label"
						:options="field.filterOptions"
						@input="fetchList"
					/>
				</td>
			</template>

			<template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
				<slot :name="slotName" v-bind="scope" />
			</template>
		</b-table>
		<div class="mx-2 mb-2">
			<b-row>
				<b-col
					cols="12"
					sm="6"
					class="d-flex align-items-center justify-content-center justify-content-sm-start"
				>
					<span class="text-muted"
						>Показаны записи {{ table.data.pagination.from }} по {{ table.data.pagination.to }} из
						{{ table.data.pagination.of }}</span
					>
				</b-col>
				<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
					<b-pagination
						v-model="table.page.value"
						:total-rows="table.data.pagination.of"
						:per-page="table.data.pagination.per"
						first-number
						last-number
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
		</div>

		<b-sidebar
			id="add-new-user-sidebar"
			:visible.sync="isSlectColumnActive"
			bg-variant="white"
			sidebar-class="sidebar-lg"
			shadow
			backdrop
			no-header
			right
			@hidden="isSlectColumnActive = false"
		>
			<template #default="{ hide }">
				<!-- Header -->
				<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
					<h5 class="mb-0">Выбрать столбцы</h5>

					<feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
				</div>

				<!-- BODY -->
				<div class="m-3">
					<b-form-group item="Form-checkbox-group stacked checkboxes">
						<b-form-checkbox-group
							v-model="selectedColumns"
							:options="optionsColumn"
							class="mb-3 p-1"
							style="padding: 5px"
							value-field="key"
							text-field="label"
							disabled-field="notEnabled"
							stacked
						/>
					</b-form-group>
				</div>
			</template>
		</b-sidebar>
	</div>
</template>

<script>
	// import store from "@/store";
	import { ref, watch, onMounted, computed, toRefs } from '@vue/composition-api'

	import {
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BSidebar,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BFormGroup,
		BFormCheckbox,
		BFormCheckboxGroup,
	} from 'bootstrap-vue'

	import format from '@/libs/format'

	export default {
		components: {
			BRow,
			BCol,
			BTable,
			BButton,
			BBadge,
			BPagination,
			BDropdown,
			BDropdownItem,
			BFormInput,
			BSidebar,
			BFormGroup,
			BFormCheckbox,
			BFormCheckboxGroup,
		},
		props: {
			api: Object,
			fields: Array,
			hasFilters: Boolean,
			params: Object,
			onClick: Function,
		},
		setup(props) {
			const { fields, params } = toRefs(props)
			const { api } = props

			if (!params.value) params.value = {}
			const isSlectColumnActive = ref(false)

			const optionsColumn = props.fields?.filter(t => t.visible)

			const selectedColumns = ref(
				JSON.parse(localStorage.getItem(`fields:${api._list.path}`))?.length > 0
					? optionsColumn
							.filter(t => !JSON.parse(localStorage.getItem(`fields:${api._list.path}`)).includes(t.key))
							.map(t => t.key)
					: [...optionsColumn.map(t => t.key)],
			)

			watch(selectedColumns, () => {
				let data = optionsColumn.filter(t => !selectedColumns.value.includes(t.key)).map(t => t.key)
				localStorage.setItem(`fields:${api._list.path}`, JSON.stringify(data))
			})

			const filteredFields = computed(a => {
				return fields.value.filter(field => selectedColumns.value?.includes(field.key))
			})

			const table = {
				data: api.data,
				fields: filteredFields,
				page: ref(1),
				sort: ref('id'),
				desc: ref(false),
				order: undefined,
			}
			table.order = computed(() => (table.desc.value ? '' : '-') + table.sort.value)

			const filters = ref(Object.fromEntries(table.fields.value.map(c => [c.key, ''])))

			function clean(r) {
				return Object.fromEntries(Object.entries(r.value).filter(([key, val]) => val !== ''))
			}

			const fetchList = async ctx => {
				if (!api.flags.listable) return
				const res = await api.list({
					page: table.page.value,
					order: table.order.value,
					...clean(filters),
					...clean(params),
				})
			}

			onMounted(fetchList)
			watch([table.page], () => {
				fetchList()
			})

			return {
				table,
				filters,
				fetchList,
				format,
				filteredFields,
				optionsColumn,
				selectedColumns,
				isSlectColumnActive,
			}
		},
	}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

[dir='ltr'] .custom-checkbox.custom-control,
[dir='ltr'] .custom-radio.custom-control {
	padding-bottom: 10px;
}
</style>
